<script>
import TemplateBase from '../../../molecules/Templates/TemplateBase';

import RangeDatePicker from '@/components/atoms/RangeDatePicker';

export default defineNuxtComponent({
  components: { RangeDatePicker, TemplateBase },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
});
</script>

<template>
  <TemplateBase
    :placeholder="__('Label date/time Field')"
    :element="stepElement"
    :step-id="stepId"
  >
    <template #templateContent>
      <div class="flex flex-col space-y-2">
        <RangeDatePicker
          date-type="datetime"
          :value="new Date().toDateString()"
          class="h-14"
        />
      </div>
    </template>
  </TemplateBase>
</template>
